@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');


.font-loader {
    font-family: 'Titillium Web', sans-serif;
    /* font-size: 1vw; */
    font-weight: bold;
    text-decoration: none;
    color: rgb(0, 204, 255);


 }

 .cycleData {
    /* text-shadow: 0 0 0.5vw rgb(0, 204, 255); */

    font-family: 'Titillium Web', sans-serif;
    font-size: 2vw;
    font-weight: bold;
    text-decoration: none;
    color: rgb(0, 204, 255);
 }

 @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
/* .block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #000;
    border-bottom: 1px solid rgba(255, 255, 255. 0.2);

}

.title {
    text-align: center;
    font-size: 100;
} */
.typeWriterMain {
    /* text-shadow: 0 0 1vw rgb(0, 204, 255); */
    font-size: 5vw;
    font-family: 'Titillium Web', sans-serif;
    /* font-size: 1vw; */
    font-weight: bolder;
    text-decoration: none;
    color: rgb(0, 204, 255);
    padding-top: 0;
    margin-top: 0;
    height: 4vw;
    display:block;
}

.typeWriterData {
    /* text-shadow: 0 0 0.5vw rgb(0, 204, 255); */

    font-size: 2.5vw;
    height: 2vw;
    display:block;
    font-family: 'Titillium Web', sans-serif;
    /* font-size: 1vw; */
    font-weight: bold;
    text-decoration: none;
    color: rgb(0, 204, 255);

}

/* #test {
} */
 #name {
    
    padding-top: 3vw;
 }

 .home {
   top: 10%;
   background: rgb(23, 23, 23);
   text-align: center;
 }

 * {
    background: rgb(23, 23, 23);
 }

 @media (max-width: 1000px) {
  .typeWriterMain {
    font-size: 12vw;
    height: 10vw;

}

  .cycleData {
    font-size: 4vw;
    height: 8vw;
  }

  .typeWriterData {
    /* text-shadow: 0 0 0.5vw rgb(0, 204, 255); */
    height: 0vw;
    font-size: 5vw;

}
 }
