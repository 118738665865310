@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');

#projects {
    width: 98%;
    left: 1%;
}
p,a {
    font-family: 'Titillium Web', sans-serif;
    font-weight: bolder;
    text-align: left;
    background-color: rgb(0, 204, 255, 0.0);
    padding: 0;
    margin: 0;
}

.img {
    background-color: rgb(0, 204, 255, 0);
    height: 10vw;
    margin-top: 0.7vw;
    width: auto;
    border-radius: 1vw;

}

.projClose {

    font-size: 2vw;
    border-radius: 1vw;
    z-index: -1;
    width: 15vw;
    height: 15vw;
    margin: 3vw;
    padding: 1.5vw;
    /* border: 0.1vw  rgba(69, 82, 81, 0.661); */
    background-color: rgb(0, 204, 255, 0.8);
    box-shadow: 0 0 0.5vw rgb(0, 204, 255, 0.8);
    float: left;
    text-align: center;

    /* vertical-align: text-top; */



}

.projTitle {
    text-align: center;

    /* font-family: 'Titillium Web', sans-serif;
    font-weight: bolder;
    text-align: center;
    padding: 0;
    margin: 0;
    background-color: rgb(0, 204, 255, 0.0); */

}
.projOpen {

    position: fixed;
    /* left: 50%; */
    /* top: 50%; */
    transform: translate(25%, 2.5%);
    z-index: 1;
    left: auto;
    padding: 3vw;
    border-radius: 1.5vw;
    background-color: rgb(0, 204, 255);
    box-shadow: 0 0 0.5vw rgb(0, 204, 255);
    width: 60vw;
    height: 40vw;
    text-align: center;

}

.bigTitle {
    padding: 0;
    margin: 0;
    font-size: 3vw;
    text-align: center;


}

.projClose:hover {
    background-color: rgba(69, 82, 81, 0.661);
    box-shadow: 0 0 1vw rgba(69, 82, 81, 0.661);

}

#description {
    padding-top: 3%;

    width :45%;
    height: 70%;
    float: left;
    font-size: 1.8vw;
    overflow-y: scroll;

    /* border: 0.1vw solid  rgba(69, 82, 81, 0.661); */
}

#demo {
    padding-top: 3%;
    float: right;
    background-color: rgb(0, 204, 255, 0);
    width: 50%;

}
#bigImage {

    float: right;
    background-color: rgb(0, 204, 255, 0);

    max-width: 100%;
    max-height: 30vw;
}

#link {
    font-size: 1.6vw;
    padding-top: 3%;
    text-align: center;
    background-color: rgb(0, 204, 255, 0);
    float: right;
}

#close {
    font-size: 1.6vw;

    position: absolute;
    left: 95%;
    background-color: rgb(0, 204, 255, 0);
}

#else {
    z-index: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    background-color: rgb(0, 204, 255, 0);
}

@media (max-width: 700px) {

    .projClose {

        font-size: 8vw;
        border-radius: 3vw;
        width: 75vw;
        height: 75vw;
        margin-left: 0;
        margin-right: 0;
        /* padding: 1.5vw; */
        left: 11vw;
        position: relative;

    
    }

    .img {
        height: 55vw;
        margin-top: 3vw;
        width: auto;
        border-radius: 3vw;


    }

    .projOpen {

        position: fixed;
        /* left: 50%; */
        /* top: 50%; */
        transform: translate(8%, 2.5%);
        padding: 5vw;
        border-radius: 3vw;
        background-color: rgb(0, 204, 255);
        box-shadow: 0 0 0.5vw rgb(0, 204, 255);
        width: 75vw;
        height: fit-content;
        max-height: 60vh;
        text-align: center;
        overflow-y: scroll;
    
    }

    #demo {
        padding-top: 3vw;
        /* float: right; */
        background-color: rgb(0, 204, 255, 0);
        width: 100%;

    
    }

    #description {
        padding-top: 3%;
    
        width :100%;
        height: auto;
        float: left;
        font-size: 4vw;
        overflow-y: scroll;
        
        
    
        /* border: 0.1vw solid  rgba(69, 82, 81, 0.661); */
    }

    #link {
        font-size: 3vw;
        padding-top: 1%;
        text-align: center;
        background-color: rgb(0, 204, 255, 0);
        float: right;
        
    }

    .bigTitle {
        font-size: 4vw;
    }

}
