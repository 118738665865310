@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');


.aText {
    font-family: 'Titillium Web', sans-serif;
    font-weight: bolder;
    background-color: rgb(0, 204, 255, 0.0);
    padding: 0;
    margin: 0;
    color: rgb(0, 204, 255, 0.8);
}

#blurb {
    /* float: right; */
    /* padding: 5%; */
    /* position: relative; */
    font-size: 1.6vw;
}

#meImg {
    padding: 6%;

    padding-top: 8%;
    float: left;
    /* position: relative; */

    width: 20%;
}

#resLink {
    /* width: 40%; */
    /* float: right; */
    /* padding: 5%; */
    /* padding-right: 1%; */
    color: white;
    /* padding-top: 10%; */
    font-size: 1.6vw;
}

.right {
    padding-right: 8%;

    padding-top: 7%;

    width: 60%;
    
    /* background-color: red; */
    float: right;
}

@media (max-width: 1000px) {

    .right {
        padding-right: 8%;
    
        padding-top: 0%;


    
        width: 80%;
        
        /* background-color: red; */
        float: right;
    }
    #blurb {

        font-size: 3.5vw;
        margin-bottom: 1vw;

    }

    #resLink {
        font-size: 3.5vw;
    }

    #meImg {
        /* padding: 6%; */
    

        /* position: relative; */
        left: 9%;
        width: 70%;
        position: relative;
    }
}