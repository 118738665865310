@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap');

/* p,a {
    font-family: 'Titillium Web', sans-serif;
    font-weight: bolder;
    background-color: rgb(0, 204, 255, 0.0);
    padding: 0;
    margin: 0;
    color: rgb(0, 204, 255, 0.8);
} */

.cText {
    font-family: 'Titillium Web', sans-serif;
    font-weight: bolder;
    background-color: rgb(0, 204, 255, 0.0);
    padding: 0;
    margin: 0;
    color: rgb(0, 204, 255, 0.8);
}
.logoImg {
    background-color: rgb(0, 204, 255, 0);
    height: 3vw;
    /* float: left; */
    padding-right: 1vw;
    width: auto;
}

#cLink {
    display: inline;
    text-align: center;

    font-size: 3vw;
}

#cTitle {
    text-align: center;
    font-size: 5vw;
    padding-bottom: 2.5vw;
}
#com {
    
    /* background-color: red; */
    text-align: center;
    padding-bottom: 2vw;
}

@media (max-width: 1000px) {
    #cTitle {

        font-size: 10vw;

    }

    #cLink {
        display: inline;
        text-align: center;
    
        font-size: 5vw;
        /* margin-bottom: 5vw; */
        
    }

    #com {
    
        /* background-color: red; */
        text-align: center;
        padding-bottom: 5vw;
    }

    .logoImg {
        background-color: rgb(0, 204, 255, 0);
        height: 4vw;
        /* float: left; */
        padding-right: 2vw;
        width: auto;
    }
    
}